import ApiService from "@/core/services/api.service";
import store from "@/core/services/store";
let url = process.env.VUE_APP_API_TENANT;
if (process.env.VUE_APP_VERSION >= 2) {
  url = process.env.VUE_APP_API_ADMIN;
}

ApiService.setHeader("x-api-key", store.getters.apiToken);
if (store.getters.apiTokenPartner) {
  ApiService.setHeader("x-partner-key", store.getters.apiTokenPartner);
}

class Mapping {
  get(id) {
    return ApiService.get(url, "mappings/" + id);
  }
  getAll(params, cancelToken) {
    let options = new URLSearchParams(params).toString();
    let headers = {};
    if (cancelToken) {
      headers.cancelToken = cancelToken;
    }
    return ApiService.get(url, "mappings?" + options, headers);
  }
  update(id, data) {
    return ApiService.put(url + "/mappings/" + id, data);
  }
  store(data) {
    return ApiService.post(url + "/mappings", data);
  }
  delete(id) {
    return ApiService.delete(url + "/mappings/" + id);
  }
  map(mapping, dataStructureDataId) {
    const data = {
      data_structure_data_id: dataStructureDataId,
      mapping: mapping
    };
    return ApiService.post(url + "/mappings/test", data);
  }
  transformers() {
    return ApiService.get(url, "mappings/transformers");
  }
}
export default new Mapping();
