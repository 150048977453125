<template>
  <div ref="wrapper">
    <div v-if="field.label && !isModal" class="row align-items-center">
      <div class="col">
        <label>
          {{ field.label }}
          <span v-if="field.required" class="red--text">*</span>
          <i
            v-if="field.helpSnippet"
            v-b-popover.hover.top="$t(field.helpSnippet)"
            class="fal fa-question-circle ml-1"
          />
        </label>
      </div>
      <div class="col-auto">
        <button class="btn btn-icon" @click="showCodeFullscreen">
          <i class="fal fa-expand"></i>
        </button>
      </div>
    </div>
    <div class="d-flex flex-nowrap">
      <div style="flex-grow: 1; max-width: 100%">
        <div>
          <div
              v-if="!validJson"
              class="mt-7 test-workflow-validation alert alert-danger"
              v-html="$t('processManager.invalidJson')"
          ></div>
          <prism-editor
            v-model="value"
            :class="prismEditorClass"
            :highlight="prismEditorHighlighter"
            :tab-size="tabSize"
            :readonly="readOnly"
            line-numbers
            style="max-width: 100%"
          />
        </div>
        <span v-if="field.hint" class="form-text text-muted">
          {{ field.hint }}
        </span>
      </div>
    </div>
    <b-modal
      id="codeFullScreen"
      ref="codeFullScreen"
      :title="$t(field.label)"
      size="xl"
      centered
      hide-footer
      modal-class="modal-code"
    >
      <div
          v-if="!validJson"
          class="mt-7 test-workflow-validation alert alert-danger"
          v-html="$t('processManager.invalidJson')"
      ></div>
      <prism-editor
        v-model="value"
        :class="prismEditorClass"
        :highlight="prismEditorHighlighter"
        :tab-size="tabSize"
        :readonly="readOnly"
        line-numbers
      />
    </b-modal>
  </div>
</template>

<script>
import { PrismEditor } from "vue-prism-editor";
import { highlight, languages } from "prismjs/components/prism-core";
import "vue-prism-editor/dist/prismeditor.min.css";
import "prismjs/components/prism-php.min";
import "prismjs/components/prism-graphql.min";
import "prismjs/components/prism-twig.min";
import "prismjs/themes/prism-tomorrow.css";

export default {
  components: { PrismEditor },
  props: {
    field: {
      type: Object
    },
    isModal: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      edited: false,
      value: "",
      tabSize: 4,
      readOnly: false,
      validJson: true
    };
  },
  computed: {
    prismEditorClass: function () {
      const style = this.field.style ?? "dark";
      let c = "code-editor";
      if (style !== "light") {
        c = "editor " + c;
      }
      return c;
    }
  },
  watch: {
    value: function () {
      if (this.field.language === 'json') {
        this.validJson = true;
        try {
          if (this.value.length)
            JSON.parse(this.value);
        } catch (e) {
          this.validJson = false;
        }
      }
      this.edited = true;
      let newValue = this.value;
      this.$set(this.field, "value", newValue);
      this.$emit("change", newValue);
    }
  },
  mounted() {
    this.value = this.field.value ?? "";
    if (this.field.readOnly) {
      this.readOnly = true;
    }
  },
  methods: {
    showCodeFullscreen() {
      this.$refs["codeFullScreen"].show();
    },
    prismEditorHighlighter(code) {
      const language = this.field.lang ?? "php";
      const grammar = languages[language];

      return highlight(code, grammar, language);
    }
  }
};
</script>

<style lang="scss">
.code-editor > .prism-editor__textarea:focus {
  outline: none;
}
.code-editor > .prism-editor__container {
  min-height: 300px !important;
}
.editor.code-editor {
  max-height: 400px;
  .modal & {
    max-height: none;
  }
}
.code-editor {
  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 13px;
  line-height: 1.5;
  padding: 5px;
  border: 1px solid #e4e6ef;
}

#codeFullScreen {
  .modal-dialog {
    max-width: 95%;
  }
  .mapping-wrapper {
    max-height: calc(100vh - 140px);
  }
  &.modal-code {
    .modal-dialog {
      height: 95vh;

      .modal-content {
        height: 100%;

        .editor {
          height: 80vh;
          overflow-y: auto;

          .prism-editor__container {
            height: auto;
          }
        }
      }
    }
  }
}
</style>
