import ApiService from "@/core/services/api.service";
import store from "@/core/services/store";

ApiService.setHeader("x-api-key", store.getters.apiToken);
if (store.getters.apiTokenPartner) {
  ApiService.setHeader("x-partner-key", store.getters.apiTokenPartner);
}

let url = process.env.VUE_APP_API_TENANT;
if (process.env.VUE_APP_VERSION >= 2) {
  url = process.env.VUE_APP_API_ADMIN;
}

class Reporting {
  getProcess(id) {
    let params = id + "?flat=true";
    return ApiService.get(url, "processes/" + params);
  }

  get(id, flat = false, days = 7) {
    let params = id;
    params += "?days=" + days;
    if (flat) {
      params += "&flat=true";
    }
    return ApiService.get(url, "reporting/process/" + params);
  }

  getChildren(id, days = 7) {
    let params = "?days=" + days;
    return ApiService.get(
      url,
      "reporting/process/" + id + "/children" + params
    );
  }

  getAll(params) {
    let options = "?page=" + params.page + "&perPage=" + params.size;
    return ApiService.get(url, "reporting/process" + options);
  }

  getIterations(processId, params, cancelToken) {
    let postData = {
      process_id: processId,
      cycle_process_iteration_id: params.cycleProcessIterationId,
      status: params.status,
      page: params.page,
      perPage: params.perPage,
      parent_process_iteration_id: params.parent_process_iteration_id,
      searchTerm: params.searchTerm
    };
    let headers = {};
    if (cancelToken) {
      headers.cancelToken = cancelToken;
    }
    return ApiService.post(
      url + "/reporting/process_iterations/" + processId,
      postData,
      headers
    );
  }

  getCycles(processId, params) {
    let options = "?page=" + params.page + "&perPage=" + params.size;
    return ApiService.get(url, "/reporting/cycles/" + processId + options);
  }
}

export default new Reporting();
