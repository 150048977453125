<template>
  <div
      v-if="currentMode"
      class="d-flex flex-nowrap align-items-start position-absolute"
      style="top: 85px; right: 16px; width: 450px"
      :style="{ width: width + 'px' }"
  >
    <div :key="componentKey" class="accordion w-100" role="tablist">
      <VueResizable
          :min-width="minWidth"
          :max-width="maxWidth"
          :active="['l']"
          class="h-100"
          :width="width"
          @resize:move="onConfigurationResizeMove"
          @mount="onMounted"
      >
        <div class="card card-custom border-0 w-100">
          <div
              class="card-header rounded justify-space-between align-items-center bg-white border-bottom"
              style="cursor: default"
          >
            <div class="card-title text-dark">
              {{
                node
                    ? $t("workflowDesigner.configureElement", {
                      label: node.attrs.label
                    })
                    : $t("workflowDesigner.selectElement")
              }}
              <div v-if="isAdmin && node" class="text-muted">
                &nbsp;- {{ node.attrs.data.hash }}
              </div>
            </div>
            <div class="card-toolbar">
              <i class="p-2 icon-xl fal fa-sync cursor-pointer" @click="reloadElement(node)"/>
              <div
                  v-b-toggle.accordion-configuration
                  class="p-2 pr-0 cursor-pointer mr-4"
              >
                <i
                    class="icon-xl fal"
                    :class="[showPanel ? 'fa-chevrons-up' : 'fa-chevrons-down']"
                />
              </div>
            </div>
          </div>
          <b-collapse
              id="accordion-configuration"
              v-model="showPanel"
              visible
              accordion="my-accordion"
              role="tabpanel"
          >
            <div class="card-body accordion-configuration-body d-flex">
              <div
                  class="bg-white pt-3 pr-3 mr-3 border-right accordion-configuration-navigation"
              >
                <i
                    v-for="(mode, i) in modesByNode"
                    :key="i"
                    v-b-tooltip.left.noninteractive="mode.label"
                    class="icon-xl mb-3 d-block"
                    :class="[
                    mode === currentMode ? 'text-primary' : '',
                    mode.icon,
                    mode.active
                      ? 'text-hover-primary cursor-pointer'
                      : 'disable-icon',
                    mode.invalid ? 'invalid' : ''
                  ]"
                    @click.prevent="mode.active ? (currentMode = mode) : ''"
                />
              </div>

              <div
                  :class="
                  currentMode.name === 'output' ? 'configuration-element' : ''
                "
                  style="width: calc(100% - 50px)"
              >
                <div v-if="currentMode.name !== 'planning' && currentMode.name !== 'documentation'">
                  <div class="text-h6 pt-3 mb-5">{{ currentMode.label }}</div>
                </div>
                <div v-else-if="currentMode.name !== 'documentation'">
                  <div class="row">
                    <b-col>
                      <div class="text-h6 pt-3 mb-5">
                        {{ currentMode.label }}
                      </div>
                    </b-col>
                    <div v-if="dummyDataExist">
                      <b-col>
                        <b-button variant="primary" @click="loadDummyData">
                          {{ $t("workflowElements.loadDummyData") }}
                        </b-button>
                      </b-col>
                    </div>
                  </div>
                </div>
                <component
                    :is="currentMode.component"
                    :node="node"
                    :process="process"
                    :config-values="configValues"
                    :output-values="outputValues"
                    :debug-values="debugValues"
                    :element-documentation="documentation"
                    class="flex-grow-1"
                />
              </div>
            </div>
          </b-collapse>
        </div>
      </VueResizable>
    </div>
  </div>
</template>

<script>
import Planning from "@/components/Projects/Workflows/Designer/Canvas/Configuration/Planning";
import Configuration from "@/components/Projects/Workflows/Designer/Canvas/Configuration/Configuration";
import Authentication from "@/components/Projects/Workflows/Designer/Canvas/Configuration/Authentication";
import Input from "@/components/Projects/Workflows/Designer/Canvas/Configuration/Input";
import Output from "@/components/Projects/Workflows/Designer/Canvas/Configuration/Output";
import Error from "@/components/Projects/Workflows/Designer/Canvas/Configuration/Error";
import Documentation from "@/components/Projects/Workflows/Designer/Canvas/Configuration/Documentation";

import {bus} from "@/main";
import ConfigValues from "@/components/Projects/Settings/Config/config";
import WorkflowElements from "@/components/Projects/Workflows/Designer/designerElements";
import Auth from "@/components/General/Auth/auth";
import axios from "axios";
import {checkTime} from "@/components/Projects/Workflows/Designer/Canvas/Components/editorHelpers";

export default {
  components: {Planning, Authentication},
  props: ["process", "outputValues", "allWorkflowElements"],
  data() {
    return {
      node: undefined,
      currentMode: undefined,
      documentation: undefined,
      dummyDataExist: undefined,
      modes: [
        {
          name: "planning",
          label: "Planung",
          component: Planning,
          icon: "fal fa-font"
        },
        {
          name: "authentication",
          label: "Authentifizierung",
          component: Authentication,
          icon: "fal fa-lock"
        },
        {
          name: "configuration",
          label: "Konfiguration",
          component: Configuration,
          icon: "fal fa-gear"
        },
        {
          name: "input",
          label: "Input",
          component: Input,
          icon: "fal fa-arrow-right-to-arc"
        },
        {
          name: "output",
          label: "Output",
          component: Output,
          icon: "fal fa-arrow-right-from-arc"
        },
        {
          name: "error",
          label: "Error Handling",
          component: Error,
          icon: "fal fa-exclamation-triangle"
        },
        {
          name: "documentation",
          label: "Dokumentation",
          component: Documentation,
          icon: "fal fa-circle-question"
        }
      ],
      showPanel: true,
      configValues: [],
      debugValues: [],
      cancelToken: null,
      maxWidth: window.outerWidth / 2,
      minWidth: 350,
      lastWidth: undefined,
      width: 450,
      componentKey: 0
    };
  },
  computed: {
    modesByNode: function () {
      let modes = [];
      this.modes.forEach(mode => {
        modes.push(mode);
        modes[modes.length - 1].active =
            !!this.node?.attrs.data[mode.name]?.length ||
            mode.name === "planning" ||
            (mode.name === "configuration" && this.node?.hasName("branch")) ||
            (mode.name === 'documentation' && this.hasDocumentation(this.node?.attrs.name))
        modes[modes.length - 1].invalid =
            this.node?.attrs.data[mode.name] &&
            this.node?.attrs.data[mode.name].invalid;
      });
      return modes;
    },
    isAdmin() {
      return Auth.getUserType() === "admin";
    },
    currentWorkflowElement() {
      if (!this.node) return;
      return this.allWorkflowElements.find(
          e => e.id === this.node.attrs.data.workflow_element_id
      );
    }
  },
  watch: {
    node() {
      this.node ? this.hasDummyData() : (this.dummyDataExist = false);
    }
  },
  mounted() {
    this.createCancelToken();
    this.getConfigValues();
    this.currentMode = this.modes[0];
    this.subscribeBusEvents();
  },
  destroyed() {
    this.unsubscribeBusEvents();
  },
  methods: {
    subscribeBusEvents() {
      bus.$on("activeNodeChanged", this.setNode);
      bus.$on("update-config-values", this.getConfigValues);
      bus.$on("fireAction", this.fireAction);
      bus.$on("change-debug-values", this.onChangeDebugValues);
      bus.$on("change-mode", this.changeMode);

      window.addEventListener("resize", this.onResize);
    },
    unsubscribeBusEvents() {
      bus.$off("activeNodeChanged", this.setNode);
      bus.$off("update-config-values", this.getConfigValues);
      bus.$off("fireAction", this.fireAction);
      bus.$off("change-debug-values", this.onChangeDebugValues);
      bus.$off("change-mode", this.changeMode);

      window.removeEventListener("resize", this.onResize);
    },
    onResize() {
      this.maxWidth = window.innerWidth / 3;
    },
    createCancelToken() {
      this.cancelToken = axios.CancelToken.source();
    },
    setNode(node) {
      this.node = node;
      this.currentMode = this.modes[0];
    },
    hasDummyData() {
      const dummyData = this.allWorkflowElements.find(
          e => e.id === this.node.attrs.data.workflow_element_id
      );

      this.dummyDataExist = dummyData.dummyData.length !== 0;
    },
    hasDocumentation(name) {
      const elementDocumentation = this.allWorkflowElements.filter(
        e => !!e.documentation && e.name === name
      );
      //console.log(elementDocumentation)
      if (elementDocumentation.length) {
        this.documentation = elementDocumentation[0].documentation;
      } else {
        this.documentation = undefined;
      }

      return elementDocumentation.length;
    },
    loadDummyData() {
      const dummyData = this.allWorkflowElements.find(
          e => e.id === this.node.attrs.data.workflow_element_id
      );
      for (const areaName of Object.keys(dummyData.dummyData)) {
        const area = dummyData.dummyData[areaName];
        Object.keys(area).forEach(fieldName => {
          var value = area[fieldName];
          if (value["fields"]) {
            value = value.fields;
          }
          if (this.node.attrs.data[areaName] === undefined) {
            return;
          }

          let field = this.node.attrs.data[areaName].find(
              field => field.name === fieldName
          );

          field ? (field.value = value) : null;
        });
      }

      this.$toast.fire({
        icon: "success",
        title: this.$t("workflowElements.setDummyData"),
        showCloseButton: true
      });
    },
    getConfigValues() {
      ConfigValues.getAll({size: 99})
          .then(response => {
            this.configValues = response.data.data;
          })
          .catch(error => {
            this.$swal.fire({
              icon: "error",
              title: this.$t("general.caution"),
              text: error.response?.data?.message
            });
          });
    },
    fireAction(field,
               cancel = false,
               silent = false,
               checkOutputValues = true,
               checkFields = true,
               checkConfig = true,
               checkGoTo = true
    ) {
      if (cancel) {
        this.cancelToken.cancel();
        this.createCancelToken();
        if (!silent) {
          bus.$emit("change-loading-state", false);
        }

        return;
      }

      // check required fields
      if (!this.checkActionRequiredFields(field)) {
        bus.$emit("fireActionFinished");
        return;
      }

      if (!silent) {
        bus.$emit("change-loading-state", true);
      }

      //Remove special characters from input parameters
      let inputData = this.setData(this.node.attrs.data.input);
      Object.keys(inputData).forEach(inputName => {
        if (!inputData[inputName] || typeof inputData[inputName] !== "string") {
          return;
        }
        inputData[inputName] = inputData[inputName].replace(/[|\\"']/g, "");
      });

      const data = {
        action: field.name,
        process_id: this.process.id,
        parameters: {
          hash: this.node.attrs.data.hash,
          authentication: this.setData(this.node.attrs.data.authentication),
          configuration: this.setData(this.node.attrs.data.configuration),
          input: inputData,
          output: this.setData(this.node.attrs.data.output),
          error: this.setData(this.node.attrs.data.error)
        }
      };
      const header = {
        cancelToken: this.cancelToken.token
      };

      let getParam = this.node.attrs.data.workflow_element_name;
      if (this.appVersion < 2) {
        getParam = this.node.attrs.data.workflow_element_id;
      }

      WorkflowElements.action(
          getParam,
          data,
          header
      )
          .then(response => {
            if (checkOutputValues) {
              this.checkOutputValues(response.data.data);
            }
            if (checkFields) {
              this.checkFields(response.data.data);
            }
            if (checkConfig) {
              this.checkConfig(response.data);
            }
            if (checkGoTo) {
              this.checkGoto(field);
            }

            if (silent) {
              return;
            }
            bus.$emit("change-loading-state", false);

            this.$toast.fire({
              icon: "success",
              title: this.$t(field.label + "Success")
            });
          })
          .catch(error => {
            if (axios.isCancel(error) || silent) {
              return;
            }
            bus.$emit("change-loading-state", false);
            this.$toast.fire({
              icon: "error",
              title: this.$t(field.label + "Error"),
              text: error
            });
          })
          .finally(() => {
            bus.$emit("fireActionFinished");
          });
    },
    checkActionRequiredFields(actionField) {
      const action = this.getActionByName(actionField.name);
      if (!action || action.requiredFields === undefined) {
        return true;
      }
      let missingFields = [];
      for (const fieldFullName of action.requiredFields) {
        const areaName = fieldFullName.split(".")[0];
        const fieldName = fieldFullName.split(".")[1];

        const field = this.node.attrs.data[areaName].find(
            f => f.name === fieldName
        );
        if (!field) {
          continue;
        }

        if (
            field.value === undefined ||
            field.value === null ||
            field.value === "" ||
            (field.type === "json" && field.value.length === 0) ||
            (field.type === "time" && !checkTime(field.value))
        ) {
          missingFields.push(this.$t(field.label));
        }
      }

      if (missingFields.length > 0) {
        this.$toast.fire({
          icon: "error",
          title: this.$t(actionField.label + "Error"),
          html:
              this.$t("workflowElements.requiredFieldsError") +
              missingFields.join(", ")
        });
      }

      return missingFields.length === 0;
    },
    checkOutputValues(data) {
      bus.$emit("removeOutputValuesByHash", this.node.attrs.data.hash);
      for (const output in data) {
        if (data[output].success !== undefined && !data[output].success) {
          continue;
        }

        const element = this.node.attrs.data.output.find(
            el => el.name === output
        );
        if (!element) {
          continue;
        }

        let children = [];
        for (const child in data[output]) {
          const childElement = {
            name: child,
            data: data[output][child]
          };
          children.push(childElement);
        }
        this.$set(element, "children", children);
        this.$set(element, "data", data[output]);

        bus.$emit("outputValueChanged", element, this.node.attrs.data.hash);
      }
    },
    checkFields(data) {
      if (data.fields === undefined) {
        return;
      }
      data.fields.forEach(f => {
        const fieldNamePats = f.name.split(".");
        const fieldArea = fieldNamePats.length > 0 ? fieldNamePats[0] : "";
        const fieldName = fieldNamePats.length > 1 ? fieldNamePats[1] : "";

        let field = this.node.attrs.data[fieldArea].find(
            f => f.name === fieldName
        );
        if (!field) return;

        this.$set(field, "options", []);
        f.options.forEach(o => {
          let value =
              typeof o === "string"
                  ? {
                    value: o,
                    label: o
                  }
                  : o;

          if (f.name === "configuration.functions") {
            value.value = o.name;
            value.label = o.name;
            value.types = o.types;
          }
          field.options.push(value);
        });

        if (field.options.length > 0) {
          const newVal =
              field.type === "multiSelect"
                  ? [field.options[0].value]
                  : field.options[0].value;
          this.$set(field, "value", newVal);

          if (field.onChange !== undefined) {
            this.fireAction(
                {
                  name: field.onChange,
                  label: field.onChange
                },
                false,
                true
            );
          }
        }
      });
    },
    checkConfig(data) {
      if (data.config === undefined || data.config === null) {
        return;
      }
      Object.keys(data.config).forEach(areaName => {
        const area = data.config[areaName];
        if (
            typeof area !== "object" ||
            this.node.attrs.data[areaName] === undefined
        ) {
          return;
        }
        this.node.attrs.data[areaName] = [];
        Object.keys(area).forEach(valKey => {
          const val = area[valKey];
          this.node.attrs.data[areaName].push(val);
        });
      });
      this.$nextTick().then(() => {
        this.componentKey += 1;
      });
    },
    checkGoto(field) {
      if (!this.currentWorkflowElement) return;
      const action = this.getActionByName(field.name);
      if (action && action.goto) {
        const mode = this.modes.find(mode => mode.name === action.goto);
        if (mode) {
          this.currentMode = mode;
        }
      }
    },
    getActionByName(name) {
      return this.currentWorkflowElement.config.actions.find(
          action => action.name === name
      );
    },
    setData(input) {
      if (!input) {
        return {};
      }
      let data = {};
      input.forEach(val => {
        data[val.name] = val.value;
      });
      return data;
    },
    onChangeDebugValues(debugValues) {
      this.debugValues = debugValues;
    },
    changeMode(newMode) {
      this.currentMode = this.modes[newMode];
    },
    onConfigurationResizeMove(payload) {
      this.lastWidth = payload.width;
    },
    onMounted() {
      this.width = this.lastWidth;
    },
    reloadElement(node) {
      const workflowElementName = node.attrs.data.workflow_element_name;
      const workflowElementId = node.attrs.data.workflow_element_id;
      const nodeConfiguration = JSON.parse(JSON.stringify(node.attrs.data.configuration));
      //Update single element (selected node)
      WorkflowElements.getSingle(workflowElementName).then(response => {
        const config = response.data.data.config;
        nodeConfiguration.forEach((entry, index) => {
          if (entry.type === 'select') {
            const freshElementFound = config.configuration.find(x => x.name === entry.name);
            node.attrs.data.configuration[index].options = freshElementFound.options;
          }
        })
      }).catch(() => {
        WorkflowElements.getSingle(workflowElementId).then(response => {
          const config = response.data.data.config;
          nodeConfiguration.forEach((entry, index) => {
            if (entry.type === 'select') {
              const freshElementFound = config.configuration.find(x => x.name === entry.name);
              node.attrs.data.configuration[index].options = freshElementFound.options;
            }
          })
        })
      })
    },

  }
};
</script>

<style lang="scss">
#accordion-configuration {
  i {
    &.disable-icon {
      opacity: 0.5;
    }
  }
}

.invalid::after {
  content: "!";
  color: red;
  font-family: Poppins, Helvetica, sans-serif;
  font-size: 18px;
}

.configuration-element {
  max-height: 72vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.accordion-configuration {
  &-body {
    max-height: 50vh;
    overflow-y: auto;
  }

  &-navigation {
    position: sticky;
    top: 0;
  }
}
</style>
